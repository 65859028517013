import './App.css';

import KeplerGl from 'kepler.gl';

import React from 'react';
import { Provider, useDispatch } from "react-redux";
import { configureStore } from '@reduxjs/toolkit'
import { taskMiddleware } from "react-palm/tasks";
import { addDataToMap } from "kepler.gl/actions";
import defaultMapConfig from "./default_map_config.json"
import keplerGlReducer from "kepler.gl/reducers";
import {processGeojson} from 'kepler.gl/processors';
import useSwr from "swr";
import GeoJSON from "geojson"


const store = configureStore({
  reducer: {
    keplerGl: keplerGlReducer
  },
  middleware: [taskMiddleware]
})

function App() {
  return (
    <Provider store={store}>
      <Map />
    </Provider>
  )
}

// Add this to filter based on census tract (Limited to 'Fishtown' 'East Kensington' and 'Olde Kensington' neighborhoods)
const censusTractsFilter = "WHERE (permits.censustract LIKE '161' OR permits.censustract like '160' OR permits.censustract like '162' OR permits.censustract like '158' OR permits.censustract like '157' OR permits.censustract like '144') "

// If you're looking for housing (likely to be) in the old tax abatement pre-2022 style
const preAbatementSwitchFilter = "AND permits.permitissuedate < '20220101' "


function Map() {
  const dispatch = useDispatch();
  const { data } = useSwr("construction", async () => {
    
    const response = await fetch(
      // Hit the Philly open data link to pull permit info from two collections
      "https://phl.carto.com/api/v2/sql?q=SELECT permits.address,permits.permitissuedate,permits.approvedscopeofwork,ST_AsGeoJSON(DOR_Parcel.the_geom)::json AS the_geom_geojson,ST_X(permits.the_geom) as long,ST_Y(permits.the_geom) as lat,permits.status as permitStatus,permits.contractorname, permits.applicanttype, permits.opa_owner "+
      // Tables used:
      // Permit Table (For grabbing permits and data)
      // Parcel (For getting polygonal boundaries of each address/plot)
      // Join on address
      "FROM permits " + 
      "RIGHT JOIN DOR_Parcel ON permits.address = DOR_Parcel.ADDR_STD " +
      // Filter based on new construction permits pulled before end of 2021 that are _not_ completed yet
      "WHERE permits.typeofwork LIKE 'NEW CONSTRUCTION%25' AND permits.status like 'ISSUED' and permits.permittype LIKE '%25BUILDING'"
    );
    const data = await response.json();
    const geo = GeoJSON.parse(data.rows, {GeoJSON: 'the_geom_geojson'})
    return processGeojson(geo);
  });

  React.useEffect(() => {
    if (data) {
      dispatch(
        addDataToMap({
          datasets: {
            info: {
              label: "Land Use",
              id: "landuse"
            },
            data
          },
          option: {
            centerMap: true,
            readOnly: true
          },
          // This config has three layers by default (only the first one is visible on load):
          // Polygon shaded by date applied for the permit
          // Heatmap of properties
          // Clustering of properties
          config: defaultMapConfig
        })
      );
    }
  }, [dispatch, data]);

  return (
    <KeplerGl
      id="construction"
      mapboxApiAccessToken={"pk.eyJ1IjoiamdyYWZmIiwiYSI6ImNsMGw4Y29kZzB0dWgzaWw0ZXBhYzNma2kifQ.R2sn6sxJmRQc4FO9xybQAQ"}
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
}

export default App;
